import log from "libs/Utils/log";

export default (function() {
  const filter = document.querySelector(".dropdown-list-coupon-type");

  function run() {
    log("ToggleDropdown:init");

    if (filter) {
      const dropDown = document.querySelector(".dropdown-menu");
      filter.addEventListener("click", function() {
        dropDown.classList.toggle("dropdown-menu--opened");
      });

      document.body.addEventListener("click", function(event) {
        if (!event.target.closest(".dropdown")) {
          dropDown.classList.remove("dropdown-menu--opened");
        }
      });
    }
  }

  return {
    run: run
  };
})();
