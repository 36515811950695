import log from "../Utils/log";
import mediator from "libs/mediator";

export default (function() {
  function run() {
    log("TopXCoupons:init");

    mediator.trigger("lazy_images:update");

    let showAllBtn = document.querySelector("[data-show-all-btn]");
    const ClassItemsShow = 'list-coupons-as-items--show';

    if(!showAllBtn) {
      return;
    }

    showAllBtn.addEventListener("click", function(event) {
      event.preventDefault();
      let coupons = document.querySelector(".list-coupons-as-items:nth-child(2)");
      coupons.classList.add(ClassItemsShow);
      this.classList.add('hidden');
    });
  }

  return {
    run: run
  };
})();
