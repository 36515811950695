import axios from "axios";
import App from "./core/facade";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

require("./pages");
document.addEventListener('DOMContentLoaded', function() {
  App.start();
  App.log("%cFlat_2016", "text-shadow: 3px 3px 0 #b257d4, -3px -3px 0 #206392; font-size: 1.5rem");
});
