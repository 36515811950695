export default (function() {
  const toggleBtn = document.getElementById("navbar-toggle");
  const mobileNav = document.getElementById("navigation-mobile");
  const backdrop = document.querySelector(".backdrop");

  let menuShown = false;

  function run(){
    if(!toggleBtn || !backdrop){
      return false;
    }

    toggleBtn.addEventListener("click", toggleNav);
    backdrop.addEventListener("click", hideNav)
    document.addEventListener("nav:hide", hideNav);
    document.addEventListener("nav:show", showNav);
  }

  function showNav() {
    mobileNav.classList.add("flat-navmenu-default--active");
    document.body.style.overflow = "hidden";
    backdrop.classList.add("backdrop--visible")
    toggleBtn.classList.add("navbar-toggle--open");
    menuShown = true;
  }

  function hideNav() {
    mobileNav.classList.remove("flat-navmenu-default--active");
    document.body.style.overflow = "";
    backdrop.classList.remove("backdrop--visible")
    toggleBtn.classList.remove("navbar-toggle--open");
    menuShown = false;
  }

  function toggleNav() {
    menuShown ? hideNav() : showNav();
  }

  return {
    run: run
  }
})()
