export default (function() {
  const navigation = document.querySelector('[data-widget="flat2016-nav"]');

  function run(){
    if (navigation) {
      let dropdownMenu = navigation.querySelectorAll('[data-dropdown]');

      dropdownMenu.forEach(el => {
        let target = document.querySelector(el.dataset.target);

        if(!target) {
          return;
        }

        [el, target].forEach(item => {
          item.addEventListener('mouseenter', ()=> {
            target.classList.add('opened');
          });

          item.addEventListener('mouseleave', ()=> {
            target.classList.remove('opened');
          });
        });
      });
    }
  }

  return {
    run: run
  }
})()
