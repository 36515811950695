export default (function() {
  const footer = document.querySelector('[data-widget="footer"]');

  function run(){
    if (footer) {
      const footerLinksWidgets = footer.querySelectorAll('[data-footer-links]');
      const footerHeaders = footer.querySelectorAll('[data-header]');

      openFooterDropdownOnClick(footerHeaders);
      showOneFooterWidgetItem(footerLinksWidgets);
    }
    
    function openFooterDropdownOnClick(headers) {
      headers.forEach(function(header) {
        let dropdownContent = footer.querySelector(header.dataset.target);

        header.addEventListener('click', ()=> {
          if (dropdownContent.classList.contains('opened')) {
            dropdownContent.classList.remove('opened');
          } else {
            dropdownContent.classList.add('opened');
          }
        });
      });
    }
    
    function showOneFooterWidgetItem(widgets) {
      widgets.forEach(function(widget) {
        let footerHeader = widget.querySelector('[data-header]');
        let footerContents = widget.querySelectorAll('[data-content]');

        if (!footerHeader) {
          footerContents.forEach(content => {
            content.style.display = 'block';
          })
        }
      });
    }
  }

  return {
    run: run
  }
})()
