export default (function() {
  const widgetsSeoText = document.querySelectorAll('.card-text .card-content');

  function addClassImgResponsive(widget) {
    let imgClass = 'img-responsive';
    let elemImg = widget.querySelectorAll('img');

    elemImg.forEach(img => {
      img.classList.add(imgClass);
    });
  }

  function alignCenterImages(widget) {
    let elemP = widget.querySelectorAll('p');

    elemP.forEach(el => {
      let style = window.getComputedStyle(el);
      let alignText  = style.getPropertyValue("text-align");
      let elemImg = el.querySelectorAll('img');

      if(alignText === "center") {
        elemImg.forEach(img => {
          img.style.margin = "0 auto";
        });
      }
    });
  }

  function wrapYouTubeIframe(widget) {
    let elemIframe = widget.querySelectorAll('iframe');

    elemIframe.forEach(frame => {
      let link = frame.getAttribute('src');

      if(link.includes('youtube')) {
        frame.classList.add('embed-responsive-item');
        let wrapper = document.createElement('div');
        wrapper.className = 'embed-responsive embed-responsive-16by9';
        wrapper.innerHTML = frame.outerHTML;
        frame.parentNode.replaceChild(wrapper, frame);
      }
    })
  }

  function run() {
    widgetsSeoText.forEach(widget => {
      if (widget) {
        addClassImgResponsive(widget);
        alignCenterImages(widget);
        wrapYouTubeIframe(widget);
      }
    })
  }

  return {
    run: run
  };
})();
